var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex align-center text-2xl mb-6"},[_c('span',{staticClass:"headline"},[_vm._v("Organisation Connections")]),_c('v-spacer'),_c('v-btn',{staticClass:"mt-3",attrs:{"color":"primary"},on:{"click":_vm.openCreateModal}},[_c('v-icon',[_vm._v("mdi-plus")]),_c('span',{staticClass:"ml-2"},[_vm._v("Create")])],1)],1),_c('ConnectionSearchBox',{attrs:{"value":_vm.routeQueryFilter},on:{"submit":_vm.onSearchSubmit}}),(_vm.loading)?_c('div',[_c('v-card',[_c('v-card-text',[_c('v-container',[_c('v-progress-circular',{staticClass:"mx-auto flex",attrs:{"indeterminate":"","color":"primary"}})],1)],1)],1)],1):_c('v-card',[_c('DataTableHeader',{attrs:{"headers":_vm.headers},on:{"update:search":function($event){_vm.searchText = $event}}}),_c('v-data-table',{attrs:{"data-has-actions":"true","headers":_vm.$filterHeaders(_vm.headers),"items":_vm.connectionList,"sort-by":"company1Text","search":_vm.searchText},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","color":"gray"},on:{"click":function($event){$event.stopPropagation();return _vm.editItem(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1)]}},{key:"item.company1Text",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"company-link",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.openCompanyModal(item, 1)}}},[_vm._v(_vm._s(item.company1Text))])]}},{key:"item.company2Text",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"company-link",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.openCompanyModal(item, 2)}}},[_vm._v(_vm._s(item.company2Text))])]}},{key:"item.actions2",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mx-auto",attrs:{"icon":"","color":"gray"},on:{"click":function($event){$event.stopPropagation();return _vm.deleteItem(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-delete")])],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }