<template>
  <v-dialog v-model="dialog" persistent max-width="900px" scrollable @keydown.esc="cancel">
    <Form ref="form" v-model="valid" lazy-validation @submit="save" onSubmit="return false;">
      <v-card>
        <v-card-title class="d-flex justify-space-between">
          <span class="headline">Create Connection</span>
          <v-btn icon @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <autocomplete
                  v-model="company1"
                  :item-disabled="connectionCompanyFilter"
                  :rules="[rules.required]"
                  :items="companies"
                  item-text="name"
                  item-value="id"
                  label="Company*"
                  dense
                  hide-details="auto"
                ></autocomplete>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <autocomplete
                  v-model="company2"
                  :item-disabled="connectionCompanyFilter"
                  :rules="[rules.required]"
                  :items="companies"
                  item-text="name"
                  item-value="id"
                  label="Company*"
                  dense
                  hide-details="auto"
                ></autocomplete>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <autocomplete
                  v-model="connectionStatus"
                  :rules="[rules.required]"
                  :items="connectionStatuses"
                  item-text="name"
                  item-value="id"
                  label="Status*"
                  dense
                  hide-details="auto"
                ></autocomplete>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <autocomplete
                  v-model="event"
                  :rules="[rules.required]"
                  :item-disabled="connectionEventFilter"
                  :items="events"
                  item-text="name"
                  item-value="id"
                  label="Event*"
                  dense
                  hide-details="auto"
                ></autocomplete>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-text-field v-model="nextSteps" label="Next Steps" dense hide-details="auto"></v-text-field>
              </v-col>
            </v-row>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-checkbox v-if="!item" v-model="createAnother" label="Create Another" dense />
          <v-btn class="ml-3 px-5" @click="cancel">Cancel</v-btn>
          <v-btn class="ml-3 px-5" color="primary" @click="save">Save</v-btn>
        </v-card-actions>
      </v-card>
    </Form>
  </v-dialog>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import { rules } from '@/helpers/validation';

export default {
  name: 'CreateConnectionModal',
  props: {
    item: {
      type: Object,
      default: null,
    },
    connections: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      dialog: true,
      createAnother: false,
      valid: true,
      rules,
      company1: null,
      company2: null,
      event: null,
      connectionStatus: null,
      nextSteps: null,
    };
  },
  computed: {
    ...mapState(['formDirty', 'companies', 'events', 'connectionStatuses']),
    ...mapGetters(['getCountriesArray']),
  },
  created() {
    if (this.item) {
      const { company1Id, company2Id, event, status, nextSteps } = this.item;

      this.company1 = company1Id;
      this.company2 = company2Id;
      this.event = event?.id || '';
      this.connectionStatus = status?.id || '';
      this.nextSteps = nextSteps || '';
    }
  },
  methods: {
    ...mapActions(['createEvent', 'updateEvent', 'createCompanyConnection', 'updateCompanyConnection']),
    connectionCompanyFilter(item) {
      if (this.company1) {
        const sameEventConnections = this.connections.filter((c) => c.eventId === this.event && (c.company1Id === this.company1 || c.company2Id === this.company1));
        return [...sameEventConnections.map((i) => i.company1Id), ...sameEventConnections.map((i) => i.company2Id)].includes(item.id);
      }
      if (this.company2) {
        const sameEventConnections = this.connections.filter((c) => c.eventId === this.event && (c.company1Id === this.company2 || c.company2Id === this.company2));
        return [...sameEventConnections.map((i) => i.company1Id), ...sameEventConnections.map((i) => i.company2Id)].includes(item.id);
      }
      return false;
    },
    connectionEventFilter(item) {
      const sameEventConnections = this.connections.filter(
        (c) => (c.company1Id === this.company1 && c.company2Id === this.company2) || (c.company2Id === this.company1 && c.company1Id === this.company2),
      );
      return [...sameEventConnections.map((i) => i.eventId)].includes(item.id);
    },
    validate() {
      return this.$refs.form.validate();
    },
    async save() {
      const isValid = this.validate();

      if (!isValid) {
        return;
      }
      const payload = {
        company1Id: this.company1,
        company2Id: this.company2,
        eventId: this.event,
        statusId: this.connectionStatus,
        nextSteps: this.nextSteps,
      };

      let res;
      if (this.item) {
        res = await this.updateCompanyConnection({ oldValue: this.item, newValue: payload });
      } else {
        res = await this.createCompanyConnection(payload);
      }

      if (res) {
        this.$emit('save');
      }

      if (this.createAnother) {
        this.clearFields();
      } else {
        this.close();
      }

      this.reset();
    },
    reset() {
      this.$refs.form.reset();
    },
    clearFields() {
      this.company1 = '';
      this.company2 = '';
      this.event = '';
      this.connectionStatus = '';
      this.nextSteps = '';
    },
    close() {
      this.dialog = false;
      this.$modal.delayedClose();
    },
    cancel() {
      if (this.formDirty && confirm('Are you sure you want to cancel?\nChanges you made will not be saved.') === false) {
        return;
      }
      this.dialog = false;
      this.$modal.delayedClose();
    },
  },
};
</script>
