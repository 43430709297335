<template>
  <div>
    <div class="d-flex align-center text-2xl mb-6">
      <span class="headline">Organisation Connections</span>
      <v-spacer></v-spacer>
      <v-btn class="mt-3" color="primary" @click="openCreateModal">
        <v-icon>mdi-plus</v-icon>
        <span class="ml-2">Create</span>
      </v-btn>
    </div>
    <ConnectionSearchBox :value="routeQueryFilter" @submit="onSearchSubmit" />

    <div v-if="loading">
      <v-card>
        <v-card-text>
          <v-container>
            <v-progress-circular class="mx-auto flex" indeterminate color="primary"></v-progress-circular>
          </v-container>
        </v-card-text>
      </v-card>
    </div>
    <v-card v-else>
      <DataTableHeader :headers="headers" @update:search="searchText = $event" />
      <v-data-table data-has-actions="true" :headers="$filterHeaders(headers)" :items="connectionList" sort-by="company1Text" :search="searchText">
        <template v-slot:item.actions="{ item }">
          <v-btn icon color="gray" @click.stop="editItem(item)">
            <v-icon small>mdi-pencil</v-icon>
          </v-btn>
        </template>
        <template v-slot:item.company1Text="{ item }">
          <span class="company-link" style="cursor: pointer" @click="openCompanyModal(item, 1)">{{ item.company1Text }}</span>
        </template>
        <template v-slot:item.company2Text="{ item }">
          <span class="company-link" style="cursor: pointer" @click="openCompanyModal(item, 2)">{{ item.company2Text }}</span>
        </template>
        <template v-slot:item.actions2="{ item }">
          <v-btn icon color="gray" class="mx-auto" @click.stop="deleteItem(item)">
            <v-icon small>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import dayjs from 'dayjs';
import DataTableHeader from '@/components/ui/DataTableHeader';
import DataTableHeaderMixin from '@/mixins/DataTableHeader';
import { DEFAULT_DATE_FORMAT } from '@/helpers/constants';
import CreateConnectionModal from '@/components/connections/CreateConnectionModal.vue';
import CreateCompanyModal from '@/components/company/CreateCompanyModal.vue';
import ConnectionSearchBox from '@/components/connections/ConnectionSearchBox.vue';
import { stringify } from '@/helpers/api';

export default {
  name: 'ConnectionList',
  components: { ConnectionSearchBox, DataTableHeader },
  data: () => ({
    headers: [
      { text: 'Edit', value: 'actions', sortable: false, width: '30px', align: 'center' },
      { text: 'Event', value: 'eventText', hideable: true, show: true },
      { text: 'Organisation', value: 'company1Text', show: true },
      { text: 'Organisation', value: 'company2Text', show: true },
      { text: 'Status', value: 'statusText', show: true },
      { text: 'Next Steps', value: 'nextSteps', hideable: true, show: false },
      { text: 'Created Date', value: 'createdAt', hideable: true, show: false },
      { text: 'Updated Date', value: 'updatedAt', hideable: true, show: false },
      { text: 'Actions', value: 'actions2', sortable: false, width: '30px', align: 'center' },
    ],
    searchText: '',
    connections: [],
    loading: false,
  }),
  computed: {
    ...mapState(['events', 'eventTypes']),
    connectionList() {
      let connections = this.connections.map((connection) => {
        return {
          ...connection,
          company1Text: connection?.company1?.name || '',
          company2Text: connection?.company2?.name || '',
          eventText: connection?.event?.name || '',
          statusText: connection?.status?.name || '',
          updatedAt: dayjs(connection.updated_at).format(DEFAULT_DATE_FORMAT),
          createdAt: dayjs(connection.created_at).format(DEFAULT_DATE_FORMAT),
        };
      });

      const { company1, company2, status, event } = this.routeQueryFilter;
      if (company1 && company2) {
        connections = connections.filter(
          (c) => (c.company1Id.toString() === company1 && c.company2Id.toString() === company2) || (c.company1Id.toString() === company2 && c.company2Id.toString() === company1),
        );
      } else if (company1) {
        connections = connections.filter((c) => c.company1Id.toString() === company1 || c.company2Id.toString() === company1);
      } else if (company2) {
        connections = connections.filter((c) => c.company1Id.toString() === company2 || c.company2Id.toString() === company2);
      }

      if (status) {
        connections = connections.filter((c) => c?.status?.id === status);
      }
      if (event) {
        connections = connections.filter((c) => c?.event?.id === event);
      }

      return connections;
    },
    routeQueryFilter() {
      return this.$route.query?.filters ? JSON.parse(this.$route.query.filters) : {};
    },
  },
  async created() {
    this.loading = true;
    this.connections = await this.fetchConnections();
    this.loading = false;
    this.fetchCompanies();
    this.fetchEvents();
  },
  methods: {
    ...mapActions(['fetchConnections', 'deleteConnection', 'fetchCompanies', 'fetchEvents']),
    onSearchSubmit(filterData) {
      if (filterData) {
        if (this.$route.query.filters !== stringify(filterData)) {
          this.$router.replace({
            path: this.$route.path,
            query: {
              filters: stringify(filterData),
            },
          });
        }
      }
    },
    openCompanyModal(item, type) {
      if (type === 1) {
        this.$showModal(
          CreateCompanyModal,
          { id: item.company1Id },
          {
            save: async () => {
              this.connections = await this.fetchConnections();
            },
          },
        );
      } else {
        this.$showModal(
          CreateCompanyModal,
          { id: item.company2Id },
          {
            save: async () => {
              this.connections = await this.fetchConnections();
            },
          },
        );
      }
    },
    openCreateModal() {
      this.$showModal(
        CreateConnectionModal,
        { connections: this.connections },
        {
          save: async () => {
            this.connections = await this.fetchConnections();
          },
        },
      );
    },
    editItem(item) {
      this.$showModal(
        CreateConnectionModal,
        { item, connections: this.connections },
        {
          save: async () => {
            this.connections = await this.fetchConnections();
          },
        },
      );
    },
    deleteItem(item) {
      const modal = this.$showConfirmModal(null, {
        confirm: async () => {
          await this.deleteConnection({ company1: item.company1Id, company2: item.company2Id, eventId: item.eventId });
          this.connections = await this.fetchConnections();
          modal.close();
        },
      });
    },
  },
  mixins: [DataTableHeaderMixin],
};
</script>

<style>
.company-link:hover {
  color: #4f4fff;
}
</style>
